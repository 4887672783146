<template>
  <router-view />
</template>

<script>
export default {
  components: {},
  data: () => ({
    loading: true,
  }),
  mounted() {},
};
</script>
